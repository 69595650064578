body {
  --e-global-color-primary: #22555f;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #525252;
  --e-global-color-accent: #61ce70;
  --e-global-color-7373d4d7: #4054b2;
  --e-global-color-2b63db7c: #23a455;
  --e-global-color-580048c2: #000;
  --e-global-color-22b268e9: #fff;
  --e-global-color-84d66b0: #f2fbff;
  --e-global-color-ad1fd74: #abcf2f;
  --e-global-color-3d48666: #b6e02d;
  --e-global-color-d317b2d: #368190;
  --e-global-color-20d27bd: #70707058;
  --e-global-color-bd65253: #ffffff;
  --e-global-typography-primary-font-family: "Verdana";
  --e-global-typography-primary-font-size: 40px;
  --e-global-typography-primary-font-weight: 700;
  --e-global-typography-secondary-font-family: "Verdana";
  --e-global-typography-secondary-font-size: 30px;
  --e-global-typography-secondary-font-weight: 700;
  --e-global-typography-text-font-family: "Open Sans";
  --e-global-typography-text-font-size: 18px;
  --e-global-typography-text-font-weight: 500;
  --e-global-typography-accent-font-family: "Verdana";
  --e-global-typography-accent-font-size: 20px;
  --e-global-typography-accent-font-weight: 700;
}

html {
  scroll-behavior: smooth;
}

.main-header {
  width: 100%;
  display: flex;
  justify-content: center;
  /* background-color: var(--e-global-color-580048c2); */
}

.header-section {
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  align-items: center;
  margin: 15px 0;
}

.header-nav {
  list-style: none;
  display: flex;
  gap: 20px;
}

.header-nav li a {
  list-style-type: none;
  text-decoration: none;
  list-style: none;
  padding: 0 16px;
  cursor: pointer;
  color: var(--e-global-color-text);
  font-family: var(--e-global-typography-accent-font-family), Sans-serif;
  font-size: var(--e-global-typography-accent-font-size);
  font-weight: var(--e-global-typography-accent-font-weight);
}

.header-nav li:hover {
  color: var(--e-global-color-3d48666);
}

.header-nav {
  display: flex;
  transition: all 0.3s ease-in-out;
}

.header-icon img {
  width: 150px;
  height: 100px;
}

.header-icon {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.toggle-menu {
  margin-top: 50px;
  visibility: hidden;
}

.header-nav li.active-link a {
  color: var(--e-global-color-ad1fd74);
  font-weight: bold;
}

.header-nav.active .header-icon {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.header-nav .nav-active {
  color: var(--e-global-color-3d48666);
}

/* ======home======= */

.home-info-first,
.home-info {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  text-align: left !important;
}

.home-info-first {
  padding: 0 50px;
}

.home-info-img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}

.home-info-bg {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0%;
}

.home-info-bg {
  position: absolute;
  top: -380px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-bg {
  position: relative;
  height: auto;
  display: flex;
  width: 45%;
  justify-content: center;
  align-items: center;
}

.home-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.home-section {
  /* justify-content: space-between; */
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
}

.home-content {
  padding: 30px;
  display: flex;
  width: 45%;
  flex-direction: column;
}

.home-flex {
  display: flex;
  justify-content: center;
  /* width: 80%; */
  align-items: center;
}

#home-contenth1 {
  font-size: 42px;
  color: var(--e-global-color-primary);
  margin-bottom: 10px;
  font-weight: 600;
  text-align: left;
}

#home-contentp {
  font-size: 15px;
  color: grey;
  text-align: left;
  margin: 10px 0 30px;
}

.home-bg img {
  width: 90%;
}

.home-button {
  display: flex;
  gap: 30px;
}

.home-button button {
  padding: 15px 30px;
  background-color: var(--e-global-color-3d48666);
  border: none;
  color: var(--e-global-color-22b268e9);
  font-size: 18px;
  border-radius: 25px;
  cursor: pointer;
}

.login-btn {
  background-color: var(--e-global-color-d317b2d) !important;
}

/* =============languAGE========= */

.language-dropdown {
  padding: 5px;
  border-radius: 10px;
  background-color: var(--e-global-color-22b268e9);
  color: var(--e-global-color-d317b2d);
  outline: none;
  border: 1px solid var(--e-global-color-primary);
  width: max-content;
  cursor: pointer;
}

/* ======process====== */
.process-main {
  display: flex;
  background-color: var(--e-global-color-d317b2d);
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  width: 100%;
}

.process-section {
  display: flex;
  justify-content: center;
  width: 80%;
  padding: 80px 0;
}

.process-flex {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.process-title {
  padding: 30px;
  width: 40%;
}

.process-title h1 {
  color: var(--e-global-color-3d48666);
  font-size: 52px;
  font-weight: bold;
  margin: 20px 0;
}

.process-content {
  width: 50%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.process-content h1 {
  font-size: 42px;
  color: var(--e-global-color-3d48666);
}

.process-content p {
  margin-top: 20px;
  width: 80%;
  text-align: justify;
  font-size: 16px;
  color: var(--e-global-color-580048c2);
}

.process-img img {
  max-width: 800px;
  border-radius: 10px;
}

/* ================facility=========== */

.facility-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-color: var(--e-global-color-84d66b0); */
}

.facility-section {
  display: flex;
  width: 80%;
  gap: 40px;
  justify-content: center;
  padding: 80px 0;
  align-items: center;
}

.facility-title {
  font-size: 20px;
}

.facility-card {
  display: flex;
  gap: 30px;
}

.facility-subcontent {
  width: 40%;
  padding: 60px 45px;
  margin-top: 30px;
  border: 1px solid var(--e-global-color-3d48666);
}

#facility-color {
  background-color: var(--e-global-color-primary);
  border-radius: 20px 0;
  color: var(--e-global-color-22b268e9) !important;
  border: none !important;
}

.facility-content {
  width: 80%;
}

.facility-subcontent h1 {
  font-size: 18px;
}

/* =feature========== */
.why-lead-central {
  text-align: center;
  padding: 80px;
}

.why-lead-central h2 {
  font-size: 36px;
  color: #2c4d3f;
  margin-bottom: 4rem;
  text-transform: capitalize;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  /* Adjust spacing between grid items */
  padding: 2rem;
  justify-content: center;
  align-items: start;
}

.feature-card {
  background-color: var(--e-global-color-22b268e9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  height: 400px;
  text-align: center;
  border: 1px solid var(--e-global-color-primary);
  max-width: 350px;
  border-radius: 0 120px 0 120px;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.feature-card:hover {
  background-color: var(--e-global-color-d317b2d);
  color: var(--e-global-color-22b268e9);
}

.feature-icon {
  font-size: 50px;
  color: #94c11f;
  padding: 15px;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 20px;
  color: #2c4d3f;
  margin-bottom: 1rem;
}

.feature-card h3:hover {
  color: var(--e-global-color-22b268e9);
}

/* =============contact============== */
.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  gap: 50px;
}

.contact-form {
  flex: 1;
  padding: 30px;
  border-radius: 10px;
}

.contact-form h2 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #fff;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 15px;
  font-size: 18px;
}

input,
textarea {
  width: 100%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid var(--e-global-color-3d48666);
  background-color: transparent;
  color: #000000;
  font-size: 16px;
  outline: none;
}

input::placeholder,
textarea::placeholder {
  color: #999999;
  font-size: 14px;
}
textarea {
  resize: none;
  height: 100px;
}

.submit-button {
  background-color: var(--e-global-color-primary);
  border: none;
  color: #fff;
  padding: 20px 20px;
  margin-top: 10px;
  border-radius: 50px;
  cursor: pointer;
  width: 50%;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  transition: transform 0.3s;
}

.submit-button:hover {
  transform: scale(1.1);
}

.contact-info {
  flex: 1;
  text-align: center;
}

.contact-info h1 {
  font-size: 3rem;
  color: #fff;
  position: relative;
  display: inline-block;
}

.contact-info h1 span {
  color: var(--e-global-color-primary);
  font-size: 100px;
}

.contact-info p {
  margin-top: 20px;
  font-size: 14px;
  color: #aaa;
  line-height: 1.6;
}

.contact-info span::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-50%);
  width: 50%;
  height: 1px;
  background-color: var(--e-global-color-primary);
}

.contact-info h5 {
  color: var(--e-global-color-primary);
  font-size: 50px;
  margin: 0;
  text-align: right;
}

.contact-info a {
  font-size: 60px;
}

.contact-info p {
  display: flex;
  justify-content: center;
}

.contact-info h2 {
  color: var(--e-global-color-primary);
}

/* ======footer============= */

.footer-div {
  width: 100%;
  color: var(--text-white);
}

.footer-main {
  background: var(--e-global-color-84d66b0);
  padding: 50px 50px 0;
  display: flex;
  gap: 70px;
  justify-content: center;
}
.footer-center-image {
  display: flex;
  align-items: center;
}
.footer-content {
  width: 25%;
  font-family: var(--font-roboto);
  line-height: 25px;
}

.footer-content h4 {
  font-weight: 800;
  font-size: 23px;
  color: var(--e-global-color-primary);
  margin: 30px 0 20px 0;
}

.footer-main h3 {
  color: var(--e-global-color-primary);
  font-weight: 800;
  margin: 20px 0;
  font-size: 23px;
}

.footer .active {
  color: var(--font-color);
}

.footer-social {
  display: flex;
  margin-top: 20px;
}

.footer-link p {
  display: block;
  font-size: 16px;
  margin-bottom: 0px;
  font-family: var(--font-roboto);
  font-weight: regular;
  color: var(--color-charcoal);
}

.footer-col {
  color: var(--e-global-color-84d66b0);
  background: var(--e-global-color-d317b2d);
  border-radius: 50px;
  width: 40px;
  height: 40px;
  line-height: 44px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
  font-size: 18px;
}

.footer-col a {
  color: var(--background-color);
  font-family: var(--font-roboto);
}

.footer-link a {
  color: var(--text-white);
  text-decoration: none;
  margin-bottom: 15px;
}

.footer-info a {
  color: var(--text-white);
  text-decoration: none;
  padding-left: 5px;
  font-size: 16px;
  float: left;
}

.footer-info {
  display: flex;
  gap: 10px;
  height: 52px;
  align-items: center;
}

.footer-privacy {
  background: var(--e-global-color-84d66b0);
  padding: 20px;
}
.footer-flex {
  justify-content: center;
  display: flex;
  font-family: var(--font-roboto);
  gap: 120px;
  color: var(--e-global-color-d317b2d);
}

.footer-term {
  display: flex;
  justify-content: center;
}

.footer-term a {
  text-decoration: none;
  color: gainsboro;
}

.footer-term a:hover {
  color: var(--font-color);
}

.footer-term {
  margin: 0 10px;
}
/* ======work========= */

.timeline-container {
  background-color: var(--e-global-color-84d66b0);
  padding: 150px 0;
  text-align: center;
}

.timeline {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  padding: 0 10px;
}

.timeline-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  text-align: center;
}

.timeline-item h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.timeline-item .timeline-content {
  font-size: 14px;
}

.timeline-item .category {
  font-weight: bold;
  margin-top: 10px;
}

.timeline-content p {
  width: 80%;
  margin: 10px 30px;
}

.timeline-circle img {
  width: 90px;
  /* background-color: var(--e-global-color-3d48666); */
  border: none;
  position: relative;
  z-index: 2;
}

/* ===========price================== */

.price-section {
  margin: 80px 0;
}

.pricing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
  color: var(--e-global-color-primary);
}

.pricing-card {
  width: 350px;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  /* background-color: white; */
  border: 1px solid gainsboro;
  text-align: center;
  transition: transform 0.3s;
}

.pricing-card:hover {
  transform: scale(1.05);
}

.card-header h3 {
  font-size: 1.5rem;
  margin: 0;
}

.card-header h2 {
  font-size: 2.5rem;
  margin: 10px 0;
  padding: 0 40px;
}

.card-header p {
  font-size: 1rem;
  display: flex;
  text-align: left;
}

.pricing-content {
  display: flex;
  padding: 0 40px;
  gap: 30px;
  align-items: center;
  /* justify-content: center; */
}

.planprice {
  display: flex;
  text-align: center;
  margin: 15px;
  font-size: 30px !important;
  justify-content: center;
}

.card-features {
  list-style: none;
  padding: 0;
  justify-content: center;
  gap: 10px;
  align-items: center;
  /* display: flex; */
  margin: 20px 0;
}

.tick-features {
  font-size: 20px;
  display: flex;
  flex-direction: column;
}

.card-button button {
  border: 1px solid var(--e-global-color-primary);
  width: 90%;
  border-radius: 10px;
  background: none !important;
  cursor: pointer;
  padding: 15px 0px;
  color: var(--e-global-color-primary);
}

.card-button button:hover {
  background-color: var(--e-global-color-primary) !important;
  color: var(--e-global-color-22b268e9);
}

.card-button {
  padding-top: 10px;
  padding-bottom: 40px;
}

.card-header h3 {
  margin-bottom: 40px;
  padding: 30px 0;
}

.price-title {
  display: block;
  text-align: center;
}

.price-title h1 {
  color: var(--e-global-color-primary);
  font-size: 50px;
  margin-bottom: 10px;
}

.plan-titleh3 {
  text-transform: uppercase;
  color: rgb(228, 224, 224);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  border-bottom: 2px solid var(--e-global-color-20d27bd);
}

@media screen and (max-width: 800px) {
  .header-nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 95px;
    right: 0;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(160, 142, 142, 0.1);
    visibility: hidden;
    opacity: 0;
  }

  .header-nav li a {
    font-size: 18px;
  }

  .header-nav {
    gap: 10px;
  }

  .header-nav li {
    margin: 5px;
    padding: 5px 30px;
    font-size: 16px;
  }

  .header-nav.active {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    visibility: visible;
    background-color: var(--e-global-color-22b268e9);
    opacity: 1;
    align-items: center;
    position: absolute;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(160, 142, 142, 0.1);
    z-index: 99;
  }

  .header-active {
    color: var(--e-global-color-3d48666);
  }

  .toggle-menu {
    visibility: visible;
    font-size: 32px;
    margin-top: 40px;
    font-weight: 900;
    color: var(--e-global-color-d317b2d);
    cursor: pointer;
  }

  .header-icon img {
    max-width: 140px;
  }

  .process-flex,
  .features {
    flex-direction: column;
    gap: 0px;
  }

  .process-section {
    padding: 25px;
  }

  .process-title,
  .contact-content {
    width: 100%;
    padding: 0;
  }

  .process-content,
  .features {
    flex-direction: column;
    margin-top: 60px;
  }

  .home-content {
    padding: 20px;
  }

  .feature-title {
    text-align: center;
    width: 100%;
    justify-content: center;
  }

  .feature-title p {
    width: 90%;
    text-align: justify;
    justify-content: center;
  }

  .feature-card {
    max-width: 100%;
  }

  .footer-section,
  .contact-flex {
    flex-direction: column;
    gap: 50px;
    padding: 40px 0;
  }

  .feature-content {
    flex-direction: column;
    gap: 0px;
  }

  .contact-container {
    flex-direction: column-reverse;
    gap: 0;
  }

  .contact-form {
    width: 100%;
    padding: 10px;
  }

  .process-content {
    width: 100%;
    margin-top: 0px;
  }

  .process-content p {
    width: 100%;
  }

  .facility-card {
    flex-direction: column;
  }

  .facility-subcontent {
    width: 80%;
  }

  .facility-section {
    padding: 0px;
    gap: 0px;
  }

  .facility-subcontent {
    padding: 30px;
  }

  .process-main {
    margin-top: 50px !important;
  }

  .timeline {
    flex-direction: column;
    gap: 20px;
  }

  .timeline-item {
    gap: 10px;
  }
  .footer-main {
    flex-direction: column;
    justify-content: flex-start !important;
    gap: 20px !important;
  }
  .footer-content {
    text-align: left !important;
    width: 100% !important;
  }
}

@media screen and (max-width: 992px) {
  .home-flex {
    flex-direction: column-reverse;
  }

  .home-section {
    margin-top: 50px;
  }

  .home-content {
    width: 90%;
    justify-content: center;
  }

  .home-content h1 {
    font-size: 32px;
  }

  .home-button button {
    padding: 10px 25px;
    font-size: 16px;
  }

  .feature-card {
    max-width: 100%;
    margin-top: 20px;
  }
  .footer-main {
    gap: 30px;
  }
  .footer-content {
    width: 30%;
  }
}

@media screen and (max-width: 600px) {
  .home-section {
    margin-top: 50px;
  }

  .home-content h1 {
    font-size: 24px;
  }

  .home-button {
    flex-direction: column;
    gap: 20px;
  }

  .home-button button {
    width: 100%;
  }

  .cta-buttons button {
    margin: 10px !important;
    width: 100%;
  }

  .process-title {
    padding: 0;
  }

  .footer-icons img {
    width: 150px;
  }

  .feature-section {
    padding: 0;
  }

  .why-lead-central {
    padding: 10px;
  }

  .feature-card li {
    font-size: 12px;
  }

  .feature-icon {
    padding: 7px;
  }

  .home-main {
    height: auto;
  }

  .process-img img {
    max-width: 320px;
  }

  .home-bg {
    width: 100%;
  }

  #home-contenth1 {
    font-size: 30px;
  }

  .home-content {
    padding: 0;
  }
  .process-content h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 400px) {
  .footer-icons img {
    width: 100px;
  }

  .contact-form-area input {
    width: 70%;
  }

  .contact-info h1 span {
    font-size: 50px;
  }

  .timeline {
    gap: 30px;
  }
  .contact-info span::before {
    display: none;
  }
  .contact-info h1 {
    margin: 0;
  }
}

@media screen and (max-width: 1600px) {
  .feature-flex-content {
    flex-direction: column;
    display: block;
    justify-content: center;
  }

  .feature-content {
    justify-content: space-around;
  }

  .feature-head h1 {
    text-align: center;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1400px) {
  .facility-section {
    flex-direction: column;
  }

  .process-main {
    margin-top: 100px;
  }
}

/* ====== */
